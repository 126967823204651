<!--
 * @Author: liujian
 * @Description: 
 * @Date: 2022-01-07 10:15:13
 * @LastEditTime: 2022-02-28 15:34:55
 * @FilePath: /three-class-fe-1/src/App.vue
-->
<template>
    <el-config-provider id="app" :locale="localeMessage">
        <router-view />
        <el-backtop />
    </el-config-provider>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import i18n from '@/i18n/i18n'

    const localeMessage = computed(() => {
        const messages: Record<string, any> = i18n.global.messages
        return messages[i18n.global.locale]
    })
</script>
