/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-26 10:54:50
 * @LastEditTime: 2021-09-26 14:11:57
 * @FilePath: /three-class-fe-1/src/i18n/messages/en.ts
 */
export default {
    name: 'en'
}
