/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-26 10:54:39
 * @LastEditTime: 2021-09-26 14:11:50
 * @FilePath: /three-class-fe-1/src/i18n/messages/zh-cn.ts
 */
export default {
    name: 'zh-cn',
    home: '首页'
}
