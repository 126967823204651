/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-08-30 15:42:43
 * @LastEditTime: 2023-04-12 15:42:47
 * @FilePath: /three-class-fe-1/src/main.ts
 */
import 'element-plus/dist/index.css'
import './assets/fonts/iconfont.css'
import './assets/css/index.scss'
import 'viewerjs/dist/viewer.css'

import App from './App.vue'
import ElementPlus from 'element-plus'
import VueViewer from 'v-viewer'
import auth from '@/common/directives/auth'
import { createApp } from 'vue'
import focus from '@/common/directives/focus'
import i18n from '@/i18n/i18n'
import router from './router'
import { store } from './store'

createApp(App)
    .use(router)
    .use(ElementPlus)
    .use(i18n)
    .use(store)
    .use(VueViewer)
    .directive('focus', focus)
    .directive('auth', auth)
    .mount('#app')
