/*
 * @Author: liujian
 * @Description:
 * @Date: 2022-02-14 16:11:42
 * @LastEditTime: 2022-02-14 16:25:51
 * @FilePath: /three-class-fe-1/src/common/directives/focus.ts
 */

import { ObjectDirective } from 'vue'

const Focus: ObjectDirective = {
    mounted(el) {
        el.focus()
    },
    updated(el) {
        el.focus()
    }
}

export default Focus
