/*
 * @Author: liujian
 * @Description:
 * @Date: 2021-09-26 10:55:45
 * @LastEditTime: 2021-09-26 13:38:00
 * @FilePath: /three-class-fe-1/src/i18n/i18n.ts
 */
import { createI18n } from 'vue-i18n'
import elementEnLocale from 'element-plus/lib/locale/lang/en'
import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'
import enLocale from './messages/en'
import zhLocale from './messages/zh-cn'

const messages = {
    en: {
        ...enLocale,
        ...elementEnLocale
    },
    'zh-cn': {
        ...zhLocale,
        ...elementZhLocale
    }
}

// 创建i18n实例
const i18n = createI18n({
    locale: 'zh-cn',
    globalInjection: true,
    messages: messages
})

export default i18n
